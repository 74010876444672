import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "git repo",
  url: "https://github.com/ArohanD/colorPicker"
}, {
  text: "live site",
  url: "https://colorgenerator.arohandutt.com/"
}];
export const tags = ['React', 'Firebase', 'Javascript'];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`Web Color Generator`}</h1>
      <p>{`After building a few visual apps, I ran into a consistent issue when generating random colors. There was no quick way to render a list of colors within a certain range. Instead, I often implemented naive solutions that randomly generated web colors, leading to common contrast/visibility issues. `}</p>
      <p>{`I built this app to generate a list of web friendly colors in either RGB, hex, or HSL based on a line users can draw on a color-wall to ensure color consistency. `}</p>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <img className="mdx-default-media" src="https://images.squarespace-cdn.com/content/v1/54c0768de4b07740895fb25f/1584324419465-SKF15J9XGQ59JSNT5R5Q/ke17ZwdGBToddI8pDm48kMa9nU8p88UwWiDVPNxz7e8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc8yJoGws3CFE2myINY3TFZR5TjsAFZGCb5QrcOpvBpz-XmUXxTSN34hoC7v1o67aE/Screen+Shot+2020-03-11+at+1.33.15+PM.png?format=1500w" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      